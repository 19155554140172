<template>
  <div class="footer-tcs">
    <div class="lg-container">
      <div class="xs-flex xs-justify-between xs-flex-wrap">
        <div class="footer-item xs-p-20">
          <h4>
            <strong>
              <span>{{ $t('footer.menu') }}</span>
            </strong>
          </h4>
          <nav-category mode="footer"></nav-category>
        </div>
        <div class="footer-item xs-p-20">
          <h4 v-if="!$auth.check()">
            <strong>
              <span>{{ $t('footer.account_registration') }}</span>
            </strong>
          </h4>
          <a href="/register" target="window" v-if="!$auth.check()">
            <span>{{ $t('footer.register') }}</span>
          </a>
          <h4>
            <strong>
              <span>{{ $t('footer.help_service') }}</span>
            </strong>
          </h4>
          <div class="clear"></div>
          <a href="http://facturacionfdb.pacfe.com.mx/" target="window">
            <span>{{ $t('footer.electronic_bill') }}</span>
          </a>
          <div class="clear"></div>
          <router-link to="/faq">
            <span>{{ $t('footer.faq') }}</span>
          </router-link>
          <div class="clear"></div>
        </div>
        <div class="footer-item xs-p-20">
          <h4>
            <span>{{ $t('footer.policies') }}</span>
          </h4>
          <router-link to="/privacy">
            <span>{{ $t('footer.privacy') }}</span>
          </router-link>
          <div class="clear"></div>
          <router-link to="/terms">
            <span>{{ $t('footer.terms') }}</span>
          </router-link>
          <router-link to="/conditions">
            <span>{{ $t('footer.conditions') }}</span>
          </router-link>
          <router-link to="/promotions">
            <span>{{ $t('footer.promotions') }}</span>
          </router-link>
          <router-link to="/campaigns-term-and-condition">
            <span>{{ $t('footer.campaigns_term_and_condition') }}</span>
          </router-link>
          <div class="clear"></div>
          <router-link to="/nutritional-content">
            <span>{{ $t('footer.nutritional_info') }}</span>
          </router-link>
          <div class="clear"></div>
        </div>
        <div class="footer-item footer-item-line xs-p-20 hidden-xs"></div>
        <div class="footer-item xs-p-20">
          <h4>
            <span>{{ $t('footer.connect_with_pizza_hut') }}</span>
          </h4>
          <a href="https://www.facebook.com/pizzahutmexico/" target="_blank">
            <span>{{ $t('footer.like_us_facebook') }}</span>
          </a>
          <div class="clear"></div>
          <a href="https://twitter.com/PizzaHut_Mexico" target="_blank">
            <span>{{ $t('footer.follow_us_twitter') }}</span>
          </a>
          <div class="clear"></div>
          <a href="https://www.youtube.com/channel/UCggVSEoNFPaYzx6YTyf2nSA" target="_blank">
            <span>{{ $t('footer.watch_us_youtube') }}</span>
          </a>
          <div class="clear"></div>
        </div>
      </div>
      <div
        class="xs-flex-center xs-mb-20 footer-text"
      >{{ $t('copyright', { year: (new Date().getFullYear()) }) }}</div>
      <p
        class="xs-flex-center xs-mb-20 footer-text text-center"
      >{{ $t('disclaimer', { year: (new Date()).getFullYear() }) }}</p>
    </div>
  </div>
</template>

<script>
import NavCategory from '../partials/NavCategory.vue'
export default {
  components: {
    NavCategory
  },
  mounted() {},
  data() {
    return {
      menus: []
    }
  },
  methods: {}
}
</script>
